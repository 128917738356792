import Vue from 'vue'
import Vuex from 'vuex'
import { IndexDBClient } from '@/api/indexeddb/IndexDBClient';

import actions from '@/store/actions';
import mutations from '@/store/mutations';
import modules from '@/store/modules';
import plugins from '@/store/plugins';
import getters from '@/store/getters';

Vue.use(Vuex)

const database = new IndexDBClient();

export default new Vuex.Store({
  state: {
    boards: database.boards || [],
    selectedBoardId: '',
    lists: [],
    inProgressListIds: [],
    waitingReviewListIds: [],
    onHoldListIds: [],
    completedListIds: [],
    cards: [],
    members: [],
    searching: '',
    selectedBoard: {}
  },
  mutations,
  actions,
  getters,
  modules,
  plugins
})
