
import {database} from './Database'
import {Card, CardStatus, Category, Checklist, Status} from "@/api/indexeddb/types";
import {StoreValue} from "idb";

export async function getTest() {
    return (await database.bvg).getAll('boards');
}

export class IndexDBClient {

    get boards(): any {
        return Promise.resolve(
            database.bvg.then(dbName => {
            return dbName.getAll('boards');
            }).catch(error => {
                throw error;
            })
        )
    }

    set boards( board: any ) {
        database.bvg.then(dbName => {
            dbName.put("boards", board, board.id).then(r => console.log("Successfully set Board: "+r));
        })
    }

    /*get lists(): any {
        return Promise.resolve(
            /!*database.bvg.then(dbName => {
                return dbName.getAll('lists');
            }).catch(error => {
                throw error;
            })*!/
        )
    }*/

    /*set lists(list: any) {
        database.bvg.then(dbName => {
            dbName.put('lists', list, list.id).then(list => console.log("Successfully Added List: "+list))
        })
    }
*/
    /*get listsInProgress(): any {
        return this.lists.then((lists: any) => {
            return lists.filter( (list: any) => list.status == Status.InProgress)
        })
    }

    get listsInReview(): any {
        return this.lists.then((lists: any) => {
            return lists.filter( (list: any) => list.status == Status.WaitingReview)
        })
    }

    get listsComplete(): any {
        return this.lists.then((lists: any) => {
            return lists.filter( (list: any) => list.status == Status.Completed)
        })
    }

    get listsOnHold(): any {
        return this.lists.then((lists: any) => {
            return lists.filter( (list: any) => list.status == Status.OnHold)
        })
    }*/
    //
    // get listsInProgress(): any {
    //     const resultSet: any[]  = [];
    //     return Promise.resolve(
    //         database.bvg.then(dbName => {
    //             return dbName.getAll('lists').then( (results: any) => {
    //                 results.forEach((result: any) => {
    //                     if(result.status == ListStatus.InProgress)
    //                     {
    //                         resultSet?.push(result);
    //                     }
    //                 })
    //                 return resultSet;
    //             });
    //         }).catch(error => {
    //             throw error;
    //         })
    //     )
    // }

    get users(): any {
        return Promise.resolve(
            database.bvg.then(dbName => {
                return dbName.getAll('users');
            }).catch(error => {
                throw error;
            })
        )
    }

    set users(card: any) {
        database.bvg.then(dbName => {
            dbName.put('users', card).then(c => console.log("Successfully Added List: "+c))
        })
    }

    get cards(): any {
        return Promise.resolve(
            database.bvg.then(dbName => {
                return dbName. getAll('cards').then( cards => {
                    return cards.filter((card: any) => {
                        return this.selectedBoard.then( board => {
                            const cardsBoardId = parseInt(card.boardId);
                            const boardId = parseInt(board[0].id);
                            return (cardsBoardId === boardId);
                        });
                    })
                });
            }).catch(error => {
                throw error;
            })
        )
    }

    set cards(card: any) {
        database.bvg.then(dbName => {
            dbName.put('cards', card).then(c => console.log("Successfully Added List: "+c))
        })
    }

    get cardsSequence(): any {
        return Promise.resolve(
            database.bvg.then(dbName => {
                return dbName.getAll('cardsSequence');
            }).catch(error => {
                throw error;
            })
        )
    }

    set cardsSequence(card: any) {
        database.bvg.then(dbName => {
            dbName.put('cardsSequence', card).then(c => console.log("Successfully Added cardsSequence: "+c))
        })
    }

    get checklists(): any {
        return Promise.resolve(
            database.bvg.then(dbName => {
                return dbName.getAll('checklists');
            }).catch(error => {
                throw error;
            })
        )
    }

    set checklists(checklist: any) {
        database.bvg.then(dbName => {
            dbName.put('checklists', checklist).then(c => console.log("Successfully Added Checklist: "+c))
        })
    }

    activeChecklistsForCardId(cardId) {
        return database.bvg.then( db => {
            return db.getAll('checklists').then((e => {
                return e.filter((checklist: any) => {
                    return checklist.cardId == cardId;
                })
            }))
        })
    }

    get cardHistory(): any {
        return Promise.resolve(
            database.bvg.then(dbName => {
                return dbName.getAll('cardHistory');
            }).catch(error => {
                throw error;
            })
        )
    }

    set cardHistory(history: any) {
        database.bvg.then(dbName => {
            dbName.put('cardHistory', history).then(c => console.log("Successfully rewrote history: "+c))
        })
    }

    getCardHistoryForCardId(cardId) {
        return this.cardHistory.then((history: any) => {
            return history.filter( (item: any) => item.cardId == cardId);
        })
    }

    get cardTimeLogs(): any {
        return Promise.resolve(
            database.bvg.then(dbName => {
                return dbName.getAll('cardLogs');
            }).catch(error => {
                throw error;
            })
        )
    }

    set cardTimeLogs(time: any) {
        database.bvg.then(dbName => {
            dbName.put('cardLogs', time).then(c => console.log("Successfully logged time: "+c))
        })
    }

    getCardLogsForCardId(cardId) {
        return this.cardTimeLogs.then((logs: any) => {
            return logs.filter( (log: any) => log.cardId == cardId);
        })
    }


    deleteChecklistItem(id) {
        database.bvg.then(dbName => {
            dbName.delete('checklists', id).then();
        })
    }

    deleteBoard(id) {
        database.bvg.then(dbName => {
            dbName.delete('boards', id).then();
        })
    }

    cardsInProgress(boardId){
        return this.cards.then((cards: any) => {
            return cards.filter( (card: any) => card.boardId == boardId && card.status.id == Status.InProgress)
        })
    }

    cardsInReview(boardId){
        return this.cards.then((cards: any) => {
            return cards.filter( (card: any) => card.boardId == boardId && card.status.id == Status.WaitingReview)
        })
    }

    cardsOnHold(boardId){
        return this.cards.then((cards: any) => {
            return cards.filter( (card: any) => card.boardId == boardId && card.status.id == Status.OnHold)
        })
    }

    cardsCompleted(boardId){
        return this.cards.then((cards: any) => {
            return cards.filter( (card: any) => card.boardId == boardId && card.status.id == Status.Completed)
        })
    }

    getBountyCards(includeUnassigned = true, boardId){
        return this.cards.then((cards: any) => {
            if(includeUnassigned)
                return cards.filter( (card: any) => card.boardId == boardId && card.type.key == Category.bug && card.status.id == Status.NotAssigned)
            return cards.filter( (card: any) => card.boardId == boardId && card.type.key == Category.bug)
        })
    }

    getDevelopmentCards(includeUnassigned = true, boardId){
        return this.cards.then((cards: any) => {
            if(includeUnassigned)
                return cards.filter( (card: any) => card.boardId == boardId && card.type.key == Category.code && card.status.id == Status.NotAssigned)
            return cards.filter( (card: any) => card.boardId == boardId && card.type.key == Category.code)
        })
    }

    getInvestigationCards(includeUnassigned = true, boardId) {
        return this.cards.then((cards: any) => {
            if(includeUnassigned)
                return cards.filter( (card: any) => card.boardId == boardId && card.type.key == Category.search && card.status.id == Status.NotAssigned)
            return cards.filter( (card: any) => card.boardId == boardId && card.type.key == Category.search)
        })
    }

    getRecommendedCards(includeUnassigned = true, boardId) {
        console.log(boardId);
        return this.cards.then((cards: any) => {
            if(includeUnassigned)
                return cards.filter( (card: any) => card.boardId == boardId && card.type.key == Category.gem && card.status.id == Status.NotAssigned)
            return cards.filter( (card: any) => card.boardId == boardId && card.type.key == Category.gem)
        })
    }

    getAssignedCards(boardId) {
        return Promise.resolve(this.cards.then((cards: any) => {
            return cards.filter( (card: any) => card.boardId == boardId && card.status.id != Status.NotAssigned && card.status.id != Status.Archived )
        }))
    }

    getUnassignedCards() {
        return Promise.resolve(this.cards.then((cards: any) => {
            return cards.filter( (card: any) => card.status.id == Status.NotAssigned)
        }))
    }


    deleteCard(card: Card) {
        database.bvg.then(dbName => {
            dbName.delete('cards', card.cardId).then();
        })
    }

    get cardTypes(): any {
        return Promise.resolve(
            database.bvg.then(dbName => {
                return dbName.getAll('cardTypes');
            }).catch(error => {
                throw error;
            })
        )
    }

    get cardStatus(): any {
        return Promise.resolve(
            database.bvg.then(dbName => {
                return dbName.getAll('cardStatus');
            }).catch(error => {
                throw error;
            })
        )
    }

    getCardStatus(id: number): CardStatus {
        return this.cardStatus.then((status: any) => {
            return status.filter( (status: any) => status.id == id)
        })
    }

    getCardByCardId(cardId: number): Card {
        return this.cards.then((cards: any) => {
            return cards.filter( (card: any) => card.cardId = cardId)
        })
    }

    clearAllData() {
        database.bvg.then( (dbName) => {
            dbName.clear('cards').then(r => console.log("cards db cleared: "+r));
            dbName.clear('boards').then(r => console.log("boards db cleared: "+r));
            dbName.clear('cardStatus').then(r => console.log("cardStatus db cleared: "+r));
            dbName.clear('cardTypes').then(r => console.log("cardTypes db cleared: "+r));
            dbName.clear('users').then(r => console.log("users db cleared: "+r));
        });
        database.deleteDatabase();
    }

    get selectedBoard() {
        return this.boards.then( (boards: any) => {
            return boards.filter((board: any) => board.isSelected == true)
        })
    }

    updateSelectedBoard(id) {
        return this.boards.then( (boards: any) => {
            return boards.filter((board: any) => {
                board.isSelected = board.id == id;
                this.boards = board;
            })
        })
    }

}