














/*if ("orientation" in screen) {
  screen.orientation.lock('portrait-primary').catch(function(error) {
    console.log(error);
  });
}*/

import { Component, Vue } from 'vue-property-decorator';

@Component
export default class App extends Vue
{
    private installBtn = 'none';
    private installer: any;

    get layout(): string {
      return this.$route.meta.layout || "default-layout";
    }

    get transitionName(): string{
      return this.$route.meta.transitionName || "none";
    }

    constructor() {
      super();
      let installPrompt: any;
      window.addEventListener('beforeinstallprompt', e => {
        e.preventDefault();
        installPrompt = e;
        this.installBtn = 'block';
      })

      this.installer = () => {
        this.installBtn = 'none';
        if (installPrompt != null) {

          installPrompt.prompt();
          installPrompt.userChoice.then(result => {
            if (result.outcome === 'accepted') {
              console.log('User accepted');
            } else {
              console.log('User denied');
            }
            installPrompt = null;
          })
        }
      }

      if (navigator.storage) {

        /*if(navigator.storage.estimate) {
          navigator.storage.estimate().then((quota: any) => {
            const percentageUsed = (quota.usage / quota.quota) * 100;
            console.log(`You've used ${percentageUsed}% of the available storage.`);
            const remaining = quota.quota - quota.usage;
            console.log(`You can write up to ${remaining / 1024 / 1024} more bytes.`);
          });
        }*/

        /* Add persistent storage to settings? */

        /*if (navigator.storage.persist) {
          let isPersisted = false;
          navigator.storage.persisted().then((persisted: any) =>
          {
            isPersisted = persisted;
            console.log(`Persisted storage granted: ${isPersisted}`);
          })

          if(!isPersisted) {
            navigator.storage.persist().then((persist: any) => {
              isPersisted = persist;
              console.log(`Persisted storage granted: ${persist}`);
            });
          }

        }*/
      }
      /*if(navigator.setAppBadge)// "setAppBadge" in navigator && "clearAppBadge" in navigator)
      {
        const unreadCount = 24;
        navigator.setAppBadge(unreadCount).catch((error) => {
          console.log(error);
        });
      }*/

    }
}
