import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/css/tailwind.css'
import {loadComponent, loadLayout} from "@/helpers/AsyncLoader";
/*import "vue-select/dist/vue-select.css";*/

import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrello, faPiedPiperAlt } from '@fortawesome/free-brands-svg-icons'
import {faList, faTrophy, faGift, faSignOutAlt, faThLarge, faFileImport, faCogs, faEllipsisH, faLock, faBars, faCheckCircle, faUnlock, faStar, faCode, faHourglassHalf, faClipboardList, faHatWizard, faSpinner, faCog, faGem, faCompass, faLaptopCode, faBug, faSearch, faAward, faExclamationCircle, faArrowLeft, faEdit, faShoppingCart, faPlus, faPlusCircle, faPlusSquare, faDatabase, faLayerGroup, faUsers, faUser, faFileAlt, faSave, faScroll, faHashtag, faImage, faTasks, faHistory} from '@fortawesome/free-solid-svg-icons'
import {faBolt, faWheelchair, faShieldAlt, faCalendarAlt, faStopwatch, faChevronRight, faUserShield, faBell,faTrashAlt, faClock, faStream, faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(faTrello, faList, faTrophy, faGift, faSignOutAlt, faThLarge, faFileImport, faCogs, faEllipsisH, faLock, faBars, faCheckCircle, faUnlock, faStar, faCode, faHourglassHalf, faClipboardList, faHatWizard, faSpinner, faCog, faGem, faCompass, faLaptopCode, faBug, faSearch, faAward, faExclamationCircle, faArrowLeft, faEdit, faShoppingCart,faPlus, faPlusCircle, faPlusSquare,  faDatabase, faLayerGroup,faUsers, faUser, faFileAlt, faSave, faScroll, faHashtag, faImage, faTasks,faHistory)
library.add(faBolt, faWheelchair, faPiedPiperAlt, faShieldAlt, faCalendarAlt, faStopwatch, faChevronRight, faUserShield, faBell, faTrashAlt, faClock, faStream, faExternalLinkAlt);
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.config.performance = true

Vue.component('default-layout', loadLayout('DefaultLayout'))
Vue.component('dashboard-layout', loadLayout('DashboardLayout'))
Vue.component('dashboard-layout-menu', loadLayout('DashboardLayoutMenu'))
Vue.component('responsive-layout', loadLayout('ResponsiveLayout'))

Vue.component('DashboardNavigation', loadComponent('DashboardNavigation'))

Vue.component('step-1', loadComponent('Step1', "setup/"))
Vue.component('step-2', loadComponent('Step2', "setup/"))
Vue.component('step-3', loadComponent('Step3', "setup/"))
Vue.component('step-4', loadComponent('Step4', "setup/"))
Vue.component('step-5', loadComponent('Step5', "setup/"))

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
/*Vue.component("v-select", VueSelect);*/

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
