import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import {loadView} from "@/helpers/AsyncLoader";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Dashboard',
    component: loadView('DashboardResponsive'),
    meta: {layout: 'responsive-layout', timeline: true}
  },
  {
    path: '/dashboardresponsive?filter=-1',
    name: 'My Dash',
    redirect: "/dashboardresponsive",
    /*component: loadView('DashboardResponsive'),*/
  },
  {
    path: '/setup',
    name: 'Setup',
    component: loadView('Setup'),
    meta: { step: '1' },
    children: [
      { path: '1', name: 'step1', meta: { step: '1' }, alias: ['', '0'] },
      { path: '2', name: 'step2', meta: { step: '2' } },
      { path: '3', name: 'step3', meta: { step: '3' } },
      { path: '4', name: 'step4', meta: { step: '4' } },
      { path: '5', name: 'step5', meta: { step: '5' } }
    ]
  },
  {
    path: '/import',
    name: 'Home',
    component: loadView('Home')
  },
  {
    path: '/dashboard',
    name: 'Dashboard1',
    component: loadView('Dashboard'),
    meta: {layout: 'dashboard-layout'}
  },
  {
    path: '/dashboardresponsive',
    name: 'My DashB',
    redirect: "/"
  },
  {
    path: '/newquestresponsive',
    name: 'New Quest',
    component: loadView('NewQuestResponsive'),
    meta: {layout: 'responsive-layout'}
  },
  {
    path: '/quests',
    name: 'Quests',
    component: loadView('Quests'),
    meta: {layout: 'dashboard-layout'}
  },
  {
    path: '/exploreresponsive',
    name: 'Discover',
    component: loadView('ExploreResponsive'),
    meta: {layout: 'responsive-layout'}
  },
  {
    path: '/leaderboardresponsive',
    name: 'Leaderboard',
    component: loadView('LeaderboardResponsive'),
    meta: {layout: 'responsive-layout'}
  },
  {
    path: '/profileresponsive',
    name: 'ProfileResponsive',
    component: loadView('ProfileResponsive'),
    meta: {layout: 'responsive-layout'}
  },
  {
    path: '/settings',
    name: 'Settings',
    component: loadView('Profile'),
    meta: {layout: 'responsive-layout', crm: "true"}
  },
  {
    path: '/badgeresponsive',
    name: 'My Badges',
    component: loadView('BadgeResponsive'),
    meta: {layout: 'responsive-layout'}
  },
  {
    path: '/questlistresponsive',
    name: 'High Value Target',
    component: loadView('QuestListResponsive'),
    meta: {layout: 'responsive-layout', subPage: 'true'},
  },
  {
    path: '/questlistbountyresponsive',
    name: 'Bounty Quests',
    component: loadView('QuestListBountyResponsive'),
    meta: {layout: 'responsive-layout', subPage: 'true'}
  },
  {
    path: '/questlistdevelopmentresponsive',
    name: 'Development Quests',
    component: loadView('QuestListDevelopmentResponsive'),
    meta: {layout: 'responsive-layout', subPage: 'true'}
  },
  {
    path: '/questlistinvestigationresponsive',
    name: 'Investigation Quests',
    component: loadView('QuestListInvestigationResponsive'),
    meta: {layout: 'responsive-layout', subPage: 'true'}
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard1',
    component: loadView('Leaderboard'),
    meta: {layout: 'dashboard-layout'}
  },
  {
    path: '/achievementsresponsive',
    name: 'Achievements',
    component: loadView('AchievementsResponsive'),
    meta: {layout: 'responsive-layout'}
  },
  {
    path: '/questdetailsresponsive',
    name: 'Quest Details',
    component: loadView('QuestDetailsResponsive'),
    meta: {layout: 'responsive-layout'}
  },
  {
    path: '/cmsresponsive',
    name: 'CMS',
    component: loadView('CMSResponsive'),
    meta: {layout: 'responsive-layout', subPage: "true", crm: "true"}
  },
  {
    path: '/about',
    name: 'About',
    component: loadView('About'),
    meta: {layout: 'responsive-layout'}
  },
  {
    path: '/newboardresponsive',
    name: 'New Board',
    component: loadView('NewBoardResponsive'),
    meta: {layout: 'responsive-layout', subPage: 'true', crm:'true'}
  },
  {
    path: '/newcardresponsive',
    name: 'New Card',
    component: loadView('NewCardResponsive'),
    meta: {layout: 'responsive-layout', subPage: 'true', crm:'true', }
  },
  {
    path: '/newplayerresponsive',
    name: 'New Player',
    component: loadView('NewPlayerResponsive'),
    meta: {layout: 'responsive-layout', subPage: 'true', crm:'true', transitionName: 'component-fade'}
  },
  {
    path: '/newcategoryresponsive',
    name: 'New Category',
    component: loadView('NewCategoryResponsive'),
    meta: {layout: 'responsive-layout', subPage: 'true', crm:'true'}
  },
/*  {
    path: '/settings',
    name: 'Settings',
    component: loadView('Settings'),
    meta: {layout: 'responsive-layout'}
  },*/
/*  {
    path: '',
    name: '',
    component: loadView(''),
    meta: {layout: ''}
  },*/
  {
    path: '*',
    component: loadView('PageNotFound')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
