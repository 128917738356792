import {DBSchema, deleteDB, openDB} from "idb"
import {
    KeyValue,
    Board,
    Card,
    User,
    Status,
    Type,
    Checklist,
    CardStatus,
    CardLog,
    CardType,
    UserStats,
    CardHistory,
    CardTimeLogs,
    CardsSequence
} from "./types";

const DATABASE_NAME = "bvg";
const VERSION = 1;

interface DatabaseSchema extends DBSchema {
    'boards': KeyValue<Board>;
    'cards': KeyValue<Card>;
    'users': KeyValue<User>;
    'status': KeyValue<Status>;
    'types': KeyValue<Type>;
    'checklists': KeyValue<Checklist>;
    'cardStatus': KeyValue<CardStatus>;
    'cardLog': KeyValue<CardLog>;
    'userStats': KeyValue<UserStats>;
    'cardTypes': KeyValue<CardType>;
    'cardHistory': KeyValue<CardHistory>;
    'cardLogs': KeyValue<CardTimeLogs>;
    'cardsSequence': KeyValue<CardsSequence>;
}

export const database = {
    bvg: openDB<DatabaseSchema>(DATABASE_NAME, VERSION, {
        upgrade:(db) => {
            if(!db.objectStoreNames.contains('boards')) {
                const store = db.createObjectStore('boards', {
                    autoIncrement: true
                });

                store.put({id : '', name : 'Bear vs Goat', statusId: Status.InProgress.valueOf(), isSelected: true}, "1").then()
            }
            if(!db.objectStoreNames.contains('cards')) {
                const store = db.createObjectStore('cards', {
                    autoIncrement:true,
                    keyPath: 'cardId'
                });

                store.put({cardId: "1", boardId: '', title:"Getting Started", heroimage:"https://images.unsplash.com/photo-1484824823018-c36f00489002?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=auto=format&fit=cover&w=800&q=80", type: {key: 0, name: 'Development', icon: 'code'}, status:{id: 1, name: 'In Progress', icon: 'code', acronym:"SD"}, description:"This is not the description that you wanted.",dateLastActivity:new Date(),}).then()
            }
            if(!db.objectStoreNames.contains('cardsSequence')) {
                const store = db.createObjectStore('cardsSequence', {
                    autoIncrement: true
                });
                store.put({id: 1}).then()
            }
            if(!db.objectStoreNames.contains('users')) {
                const store = db.createObjectStore('users', {
                    autoIncrement:true
                });
                store.put({name:"Bat", surname: "Trooper", username: "Schmancy", avatarUrl: "https://images.unsplash.com/photo-1584847642060-a46e239155a8?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3Dt&fit=cover&w=1349&q=80"}).then();
            }
            if(!db.objectStoreNames.contains('cardTypes')) {
                const store = db.createObjectStore('cardTypes', {
                    autoIncrement:true
                });
                store.put({key: 0, name: 'Development', icon: 'code'}).then();
                store.put({key: 1, name: 'Bounty', icon: 'bug'}).then();
                store.put({key: 2, name: 'High Value Target', icon: 'gem'}).then();
                store.put({key: 3, name: 'Investigation', icon: 'search'}).then();
            }
            if(!db.objectStoreNames.contains('cardStatus')) {
                const store = db.createObjectStore('cardStatus', {
                    autoIncrement:true
                });

                store.put({id: 0, name: 'Not Assigned', icon: 'code', acronym:"NA"}).then();
                store.put({id: 1, name: 'In Progress', icon: 'code', acronym:"IP"}).then();
                store.put({id: 2, name: 'Waiting Review', icon: 'clipboard-list', acronym:"RR"}).then();
                store.put({id: 3, name: 'On Hold', icon: 'hourglass-half', acronym:"OH"}).then();
                store.put({id: 4, name: 'Completed', icon: 'hat-wizard', acronym:"C"}).then();
                store.put({id: 5, name: 'Archived', icon: 'hat-wizard', acronym:"A"}).then();
            }
            if(!db.objectStoreNames.contains('checklists')) {
                const store = db.createObjectStore('checklists', {
                    autoIncrement: true,
                    keyPath: 'cardId',
                });

                store.put({cardId: "1", items: [
                    {id: "1", title : 'Check off your very first checklist item!', isComplete: false},
                    {id: "2", title : 'Change the status of a card.', isComplete: false},
                    {id: "3", title : 'Create a new card.', isComplete: false},
                    {id: "4", title : 'Update the title and description of your card.', isComplete: false},
                    {id: "5", title : 'Keep track of the time spent on each card by logging your time.', isComplete: false},
                    {id: "6", title : 'Updating your profile picture!', isComplete: false},
                    {id: "7", title : 'Updating your profile information.', isComplete: false},
                    {id: "8", title : 'Download and install the PWA!', isComplete: false},
                    {id: "9", title : 'All data is currently stored on your phone.', isComplete: false},
                    {id: "10", title : 'Gamification elements coming soon.', isComplete: false},
                    {id: "11", title : 'Syncing data is coming soon.', isComplete: false}
                ]}).then()
            }

            if(!db.objectStoreNames.contains('cardHistory')) {
                const store = db.createObjectStore('cardHistory', {
                    autoIncrement: true,
                    keyPath: 'cardId',
                });

                store.put({"cardId":"1","items":[
                    {"id":"1_09099231522523104_0","date":new Date(),"card":{"cardId":"1","boardId":"","title":"Getting Started","heroimage":"https://images.unsplash.com/photo-1484824823018-c36f00489002?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=auto=format&fit=cover&w=800&q=80","type":{"key":0,"name":"Development","icon":"code"},"status":{"id":1,"name":"In Progress","icon":"code","acronym":"SD"},"description":"This is not the description that you wanted.","dateLastActivity":new Date()}}
                    ]}).then()
            }

            if(!db.objectStoreNames.contains('cardLogs')) {
                const store = db.createObjectStore('cardLogs', {
                    autoIncrement: true,
                    keyPath: 'cardId',
                });
            }
        },
        terminated: () => {
            console.log("terminated");
        },
    }),
    deleteDatabase() {
        window.indexedDB.deleteDatabase("bvg");
        deleteDB("bvg").then(r => console.log("Database Deleted: "+r));

    }
};
