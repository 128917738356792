import {IndexDBClient} from "@/api/indexeddb/IndexDBClient";
import {Status} from "@/api/indexeddb/types";

const database = new IndexDBClient();

export default {
    getSelectedBoard(state: any) {
        if(state.selectedBoardId == "") {
            return database.boards.then((boards: any) => {
                return boards.filter((b: any) => b.isSelected);
            })
        }
        return state.selectedBoardId;
    },

    getListsInProgress(state: any) {
        //inProgress: any = [];
        //if(state.inProgressListIds == []) {
        //     state.inProgressListIds = database.lists.then((lists: any) => {
        //         lists.forEach((list: any) => {
        //             if(list.status == ListStatus.InProgress)
        //             {
        //                 state.inProgressListIds.push(list);
        //             }
        //         })
        //     })
        //}
        return state.inProgressListIds;
    }


}