/***
 *  This Helper Class is to provide route level code-splitting
 *  which is lazy-loaded when the route is visited.
 */

/**
 * @param name of the view to be loaded and the
 * separate chuck (view-[request].[hash].js) generated for this route
 */
export const loadView = (name: string) => {
    return () => import(
    /* webpackChunkName: "view-[request]" */
    /* webpackMode: "lazy" */
    `@/views/${name}`)
}

/**
 * @param subdir
 * @param name of the component to be loaded and the
 * separate chuck (component-[request].[hash].js) generated for this route
 */
export const loadComponent = (name: string, subdir?: string) => {
    const subdirectory = subdir || "";
    return () => import(
        /* webpackChunkName: "component-[request]" */
        /* webpackMode: "lazy" */
        `@/components/${subdirectory}${name}`)
}

/**
 * @param name of the layout to be loaded and the
 * separate chuck (layout-[request].[hash].js) generated for this route
 */
export const loadLayout = (name: string) => {
    return () => import(
        /* webpackChunkName: "layout-[request]" */
        /* webpackMode: "lazy" */
        `@/layouts/${name}`)
}