export interface KeyValue<T> {
    key: string;
    value: T;
}

export interface Board {
    id: string;
    name: string;
    statusId: number;
    isSelected: boolean;
}
export interface Card {
    id?: number;
    boardId: "";
    cardId: string;
    title: string;
    type: CardType;
    status: CardStatus;
    description: string;
    dateLastActivity: Date;
    highValueTarget?: boolean;
    userId?: number;
    heroimage?: string;
}
export interface User {
    id?: string;
    avatarUrl: string;
    name: string;
    surname: string;
    username: string;
}
export interface CheckListItems {
    id: string;
    title: string;
    isComplete: boolean;
}
export interface Checklist {
    cardId: string;
    items: CheckListItems[];
}

export interface CardHistoryItems {
    id: string;
    date: Date;
    card: Card;
}

export interface CardHistory {
    cardId: string;
    items: CardHistoryItems[];
}

export interface TimeLogItems {
    id: string;
    date: Date;
    time: string;
    description: string;
}

export interface CardTimeLogs {
    cardId: string;
    items: TimeLogItems[];
}


export interface CardStatus {
    id: number;
    name: string;
    icon: string;
    acronym: string;
}
export interface UserStats{
    id: string;
    xp: number;
    reward: string;
}
export interface CardLog{
    id: string;
    name: string;
    description: string;
    date: Date;
}

export interface CardType {
    key: number;
    name: string;
    icon: string;
}

export interface CardsSequence {
    id: number;
}

/*interface CardTypeEnumInstance {
    key: number;
    value: string;
}

interface CardTypeEnum {
    [key: string]: CardTypeEnumInstance;
}

export const CardType: CardTypeEnum = {
    code: { key: 0, value: 'code'},
    bug: { key: 1, value: 'bug'},
    gem: { key: 2, value: 'gem'},
    search: { key: 3, value: 'search' }
};*/

export enum Category {
    'code',
    'bug',
    'gem',
    'search'
}

export enum Type {
    'NotAssigned',
    'Doing',
    'NeedsReview',
    'OnHold',
    'Completed',
    'Archived'
}
export enum Status {
    'NotAssigned',
    'InProgress',
    'WaitingReview',
    'OnHold',
    'Completed',
    'Archived'
}
/*
* webhooks
* workflows
* workflowSteps
**/