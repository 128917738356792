/* eslint-disable no-console */

import { register } from 'register-service-worker'
import alertify from 'alertifyjs';

const notifyUserAboutUpdate = worker => {
  alertify.confirm("New Content!", () => {
    worker.postMessage({action: "skipWaiting"});
  });
}
const version = 1;
const assetCacheName = `bvg-assets-${version}`;

const filesToCache = [
  '/css/*.*',
  '/js/*.*',
  '/img/*.*',
  '/',
];

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered (registration) {
      console.log('Service worker has been registered.')
      caches.open(assetCacheName).then(function(cache) {
        console.log('[ServiceWorker] Caching app shell');
        return cache.addAll(filesToCache);
      })
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound (registration) {
      console.log('New content is downloading.')
    },
    updated (registration) {
      console.log('New content is available; please refresh.')
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })

  let refreshing = false;
  navigator.serviceWorker.addEventListener('controllerchange', function() {
    if(refreshing) return;
    window.location.reload();
    refreshing = true;
  })
}
