import {IndexDBClient} from "@/api/indexeddb/IndexDBClient";
import {Board, Status} from "@/api/indexeddb/types";

const database = new IndexDBClient();

export default {

    addBoards(state: any, payload: Board) {
        database.boards = payload;
        database.boards.then((boards: any) =>
            state.boards = boards.boards
        );
    },
    updatedSelectedBoardId(state: any, payload: any) {
        state.selectedBoardId = payload.value;
        state.boards.then((allBoards: any) => {
            allBoards.forEach((board: any) => {
                if(board.id == state.selectedBoardId)
                {
                    board.isSelected = true;
                    database.boards = board;
                }
                else if (board.isSelected)
                {
                    board.isSelected = false
                    database.boards = board;
                }
            });
        })
    },
    /*addLists(state: any, payload: List) {
        console.log(payload)
        database.lists = payload;
        database.lists.then((lists: any) =>
            state.lists = lists
        );
    },
    updateInProgressListIds(state: any, payload: any) {
        state.inProgressListIds = payload.inProgressListIds;
        console.log(payload.inProgressListIds);
         state.lists.then((allLists: any) => {
             allLists.forEach((list: any) => {
                if( state.inProgressListIds.includes(list.id) )
                {
                    list.status = Status.InProgress
                    database.lists = list;
                }
             });
         })
    },
    updateWaitingReviewListIds(state: any, payload: any) {
        state.waitingReviewListIds = payload.waitingReviewListIds;
        console.log(payload.waitingReviewListIds);
        state.lists.then((allLists: any) => {
            allLists.forEach((list: any) => {
                if( state.waitingReviewListIds.includes(list.id) )
                {
                    list.status = Status.WaitingReview
                    database.lists = list;
                }
            });
        })
    },
    updateOnHoldListIds(state: any, payload: any) {
        state.onHoldListIds = payload.onHoldListIds;
        console.log(payload.onHoldListIds);
        state.lists.then((allLists: any) => {
            allLists.forEach((list: any) => {
                if(state.onHoldListIds.includes(list.id))
                {
                    list.status = Status.OnHold
                    database.lists = list;
                }
            });
        })
    },
    updateCompletedListIds(state: any, payload: any) {
        state.completedListIds = payload.completedListIds;
        console.log(payload.completedListIds);
        state.lists.then((allLists: any) => {
            allLists.forEach((list: any) => {
                if(state.completedListIds.includes(list.id))
                {
                    list.status = Status.Completed
                    database.lists = list;
                }
            });
        })
    }*/

}